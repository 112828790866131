import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { Events } from './types';
import appConfig from '../../../.application.json';

const createController: CreateControllerFn = async ({
  controllerConfig,
  appData,
  flowAPI,
}) => {
  const { setProps } = controllerConfig;

  const config = flowAPI.controllerConfig;
  const api = config.wixCodeApi;

  const navigation = {
    baseUrl: api.location.baseUrl,
    navigateTo: api?.location?.navigateTo,
    navigateToUrl: api?.location?.to,
  };

  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const handler = createEventHandler<Events>(publicData);
  const onHandlerEvent = handler.on.bind(this);
  const onHandlerReset = handler.onReset.bind(this);

  const instanceToken =
    api?.site?.getAppToken && api.site.getAppToken(appConfig.appDefinitionId);

  return {
    async pageReady() {
      setProps({
        onHandlerEvent,
        onHandlerReset,
        navigation,
        instanceToken,
      });
    },
    updateConfig($w, c) {
      handler.notify(c.publicData.COMPONENT || {});
    },
  };
};

export default createController;
